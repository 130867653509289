
import { Component, Vue } from "vue-property-decorator";
import PageTitleBar from "@/components/PageTitleBar.vue";
import AttachmentIcon from "@/components/AttachmentIcon.vue";
import PostDetailsPagination from "@/components/PostDetailsPagination.vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";

import { BrainLibraryDTO } from "@/interfaces/BrainLibrary.dto";
import { BreadCrumbDTO } from "@/interfaces/BreadCrumb.dto";

import { formatIOSDateToMMDDYYYY } from "../utils/Functions";

const brainLibraryDefault = {
  _id: "",
  title: "",
  description: "",
  scheduleDate: "",
  type: "",
  subtype: "",
  brainLibraryAttachments: [],
  publisherName: "",
  publisherEmail: "",
};

@Component({
  components: {
    PageTitleBar,
    AttachmentIcon,
    PostDetailsPagination,
    BreadCrumbs,
  },
})
export default class PostDetails extends Vue {
  brainLibrary: BrainLibraryDTO = {
    ...brainLibraryDefault,
    previous: [brainLibraryDefault],
    next: [brainLibraryDefault],
  };

  breadCrumbItems: Array<BreadCrumbDTO> = [];

  brainLibraryId = "";
  libraryTypeMaped: any = "";
  postTitle = "";

  mapLibraryTypeParamToDescriptionAndPath = {
    investment_strategy: {
      title: "Investment strategy",
      rootPath: "/home/brain-library/investment_strategy",
      subtype: false,
    },
    alternative_investments: {
      title: "Alternative investments",
      rootPath: "/home/brain-library/alternative_investments",
      subtype: false,
    },
    market_update: {
      title: "Markets and managers updates",
      rootPath: "/home/brain-library/market_update",
      subtype: false,
    },
    management_cards: {
      title: "Cartas da Gestão",
      rootPath: "/home/brain-library/market_update",
      subtype: false,
    },
    private_debt: {
      title: "Private debt",
      rootPath: "/home/brain-library/private_debt",
      subtype: true,
    },
    private_equity: {
      title: "Private equity",
      rootPath: "/home/brain-library/private_equity",
      subtype: true,
    },
    real_estate: {
      title: "Real estate",
      rootPath: "/home/brain-library/real_estate",
      subtype: true,
    },
    specialty_finance: {
      title: "Specialty finance",
      rootPath: "/home/brain-library/specialty_finance",
      subtype: true,
    },
    venture_capital: {
      title: "Venture capital",
      rootPath: "/home/brain-library/venture_capital",
      subtype: true,
    },
    esg: {
      title: "Responsible investments (ESG)",
      libraryPageParam: "/home/brain-library/esg",
      show: true,
    },
    fos: {
      title: "Family office solutions (FOS)",
      libraryPageParam: "/home/brain-library/fos",
      show: true,
    },
  };

  mounted(): void {
    this.brainLibraryId = this.$route.params.postId;
    this.postTitle = this.$route.params.postTitle;

    const libraryTypeParam = this.$route.params.libraryType;
    this.libraryTypeMaped = (
      this.mapLibraryTypeParamToDescriptionAndPath as any
    )[libraryTypeParam];

    if (this.brainLibraryId && this.libraryTypeMaped) {
      this.refresh();
      this.createBreadCrumbItem();
      return;
    }

    this.$router.push("/");
  }
  async refresh(): Promise<void> {
    try {
      const { data } = await this.$api.get(
        `/library/library-access/${this.brainLibraryId}`
      );
      this.brainLibrary = data;
    } catch (error) {
      console.error(error);
    }
  }

  createBreadCrumbItem(): void {
    this.breadCrumbItems = [
      {
        text: this.libraryTypeMaped.title,
        goToPath: this.libraryTypeMaped.rootPath,
        clickable: true,
      },
      {
        text: this.postTitle,
        goToPath: "",
        clickable: false,
      },
    ];

    if (this.libraryTypeMaped.subtype) {
      this.breadCrumbItems.unshift({
        text: "Alternative investments",
        goToPath: "/home/brain-library/alternative_investments",
        clickable: true,
      });
    }
  }

  formatDate(date: string): string {
    return formatIOSDateToMMDDYYYY(date || "");
  }

  toBack(): void {
    this.$router.back();
  }
}
