
import { Component, Vue } from "vue-property-decorator";
import PageTitleBar from "@/components/PageTitleBar.vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import AlternativeInvestments from "@/components/AlternativeInvestments.vue";
import VsPagination from "@vuesimple/vs-pagination";
import PostCard from "@/components/PostCard.vue";

import { BrainLibraryDTO } from "@/interfaces/BrainLibrary.dto";
import { BrainLibraryAttachmentDTO } from "@/interfaces/BrainLibrary.dto";
import { BreadCrumbDTO } from "@/interfaces/BreadCrumb.dto";

import { arrayMothByPosition } from "./../utils/Constants";
import { getQuarter } from "@/utils/Functions";

type paramsType = {
  page: number;
  libraryType?: string;
  librarySubtype?: string;
};

export interface BrainLibraryYearMonthDTO {
  _id: string;
  title: string;
  description: string;
  scheduleDate: string;
  type: string;
  subtype?: string;
  year: string;
  mounth: string;
  groupByValue: string;
  brainLibraryAttachments?: Array<BrainLibraryAttachmentDTO>;
}

export interface GroupedBrainLibraryYearMonthDTO {
  month: string;
  year: string;
  items: Array<BrainLibraryYearMonthDTO>;
}

@Component({
  components: {
    PageTitleBar,
    BreadCrumbs,
    AlternativeInvestments,
    PostCard,
    VsPagination,
  },
})
export default class LibraryTypePage extends Vue {
  items: Array<BrainLibraryDTO> = [];
  itemsYearMonth: Array<BrainLibraryYearMonthDTO> = [];
  groupedItems: Array<GroupedBrainLibraryYearMonthDTO> = [];

  breadCrumbItems: Array<BreadCrumbDTO> = [];

  currentPagePagination = 1;
  totalPagesPagiantion = 0;

  libraryTypeMaped: any = "";

  mapLibraryTypeParam = {
    investment_strategy: {
      requestLibraryType: "INVESTMENT_STRATEGY",
      title: "Investment strategy",
      pathProp: "investment_strategy",
      subtype: false,
    },
    alternative_investments: {
      requestLibraryType: "ALTERNATIVE_INVESTMENTS",
      title: "Alternative investments",
      pathProp: "alternative_investments",
      subtype: false,
    },
    market_update: {
      requestLibraryType: "MARKET_UPDATE",
      title: "Markets and managers updates",
      pathProp: "market_update",
      subtype: false,
    },
    private_debt: {
      requestLibraryType: "PRIVATE_DEBT",
      title: "Private debt",
      pathProp: "private_debt",
      subtype: true,
    },
    private_equity: {
      requestLibraryType: "PRIVATE_EQUITY",
      title: "Private equity",
      pathProp: "private_equity",
      subtype: true,
    },
    real_estate: {
      requestLibraryType: "REAL_ESTATE",
      title: "Real estate",
      pathProp: "real_estate",
      subtype: true,
    },
    specialty_finance: {
      requestLibraryType: "SPECIALTY_FINANCE",
      title: "Specialty finance",
      pathProp: "specialty_finance",
      subtype: true,
    },
    venture_capital: {
      requestLibraryType: "VENTURE_CAPITAL",
      title: "Venture capital",
      pathProp: "venture_capital",
      subtype: true,
    },
    esg: {
      requestLibraryType: "ESG",
      title: "Responsible investments (ESG)",
      pathProp: "esg",
      subtype: false,
    },
    fos: {
      requestLibraryType: "FOS",
      title: "Family office solutions (FOS)",
      pathProp: "fos",
      subtype: false,
    },
  };

  get isSubtype(): boolean {
    const mapedLibraryType = (this.mapLibraryTypeParam as any)[
      this.libraryTypeMaped.pathProp
    ];
    return mapedLibraryType?.subtype ?? false;
  }

  get quartersItems(): GroupedBrainLibraryYearMonthDTO[] {
    const quarters: GroupedBrainLibraryYearMonthDTO[] = [];

    this.groupedItems.forEach((group) => {
      const index = quarters.findIndex((content) => {
        const quarter = getQuarter(group.month);
        return quarter === content.month && group.year === content.year;
      });

      if (index === -1) {
        quarters.push({
          month: getQuarter(group.month),
          year: group.year,
          items: [...group.items],
        });
      } else {
        quarters[index].items.push(...group.items);
      }
    });
    return quarters;
  }

  mounted(): void {
    const libraryTypeParam = this.$route.params.libraryType;
    const mapedLibraryType = (this.mapLibraryTypeParam as any)[
      libraryTypeParam
    ];

    if (mapedLibraryType) {
      this.libraryTypeMaped = mapedLibraryType;
      this.createBreadCrumbItem();

      if (this.libraryTypeMaped.pathProp !== "alternative_investments")
        this.refresh();
    } else {
      this.$router.push("/home");
    }
  }

  async refresh(): Promise<void> {
    try {
      const params: paramsType = { page: this.currentPagePagination };

      if (this.libraryTypeMaped.subtype) {
        params.librarySubtype = this.libraryTypeMaped.requestLibraryType;
      } else {
        params.libraryType = this.libraryTypeMaped.requestLibraryType;
      }

      const { data } = await this.$api.get("/library/library-access", {
        params,
      });
      this.items = data.records;
      this.createItemsWhitYearAndMonth(this.items);
      this.totalPagesPagiantion = data.pages || 1;
    } catch (error) {
      console.error(error);
    }
  }

  createBreadCrumbItem(): void {
    this.breadCrumbItems = [
      {
        text: this.libraryTypeMaped.title,
        goToPath: "",
        clickable: false,
      },
    ];

    if (this.libraryTypeMaped.subtype) {
      this.breadCrumbItems.unshift({
        text: "Alternative investments",
        goToPath: "/home/brain-library/alternative_investments",
        clickable: true,
      });
    }
  }

  createItemsWhitYearAndMonth(items: Array<BrainLibraryDTO>): void {
    this.itemsYearMonth = [];
    items.forEach((brainLibrary) => {
      const [month, year] = this.extractMonthAndYearFromDate(
        brainLibrary.scheduleDate
      );
      let brainLibraryYearMonth: BrainLibraryYearMonthDTO = {
        ...brainLibrary,
        year: year,
        mounth: month,
        groupByValue: year + "-" + month,
      };
      this.itemsYearMonth.push(brainLibraryYearMonth);
    });
    this.groupItemsWithYearAndMonth(this.itemsYearMonth);
  }

  groupItemsWithYearAndMonth(array: Array<BrainLibraryYearMonthDTO>): void {
    const property = "groupByValue";
    const groupResult = array.reduce((r: any, a) => {
      (r[a[property]] = r[a[property]] || []).push(a);
      return r;
    }, []);

    this.groupedItems = [];
    for (const [key, value] of Object.entries(groupResult) as any) {
      let keySplit = key.split("-");
      const groupedItem = {
        year: keySplit[0],
        month: keySplit[1],
        items: value,
      };
      this.groupedItems.push(groupedItem);
    }
    this.groupedItems = this.sortingDescendingArrayItems(this.groupedItems);
  }

  sortingDescendingArrayItems(
    array: Array<GroupedBrainLibraryYearMonthDTO>
  ): Array<GroupedBrainLibraryYearMonthDTO> {
    return array.sort(function (elementA: any, elementB: any) {
      return parseFloat(elementB.year) - parseFloat(elementA.year);
    });
  }

  extractMonthAndYearFromDate(date: string): Array<string> {
    let dateSplitT = date.split("T");
    let dateSplit = dateSplitT[0].split("-");
    let year = dateSplit[0];
    let month = dateSplit[1];
    return [month, year];
  }

  resetPage(): void {
    if (this.currentPagePagination !== 1) this.currentPagePagination = 1;
  }

  changePage(page: number): void {
    if (this.currentPagePagination !== page) {
      this.currentPagePagination = page;

      setTimeout(() => {
        if (this.libraryTypeMaped.pathProp !== "alternative_investments") {
          this.refresh();
        } else {
          const alternativeInvestments: any = this.$refs.AlternativeInvestments;
          if (alternativeInvestments) alternativeInvestments.refresh();
        }
      }, 10);
    }
  }

  changeTotalPage(page: number): void {
    this.totalPagesPagiantion = page;
  }

  textMonth(monthNumber: string): string {
    return arrayMothByPosition[parseInt(monthNumber)];
  }
}
