
import { Component, Vue } from "vue-property-decorator";
import PostCard from "@/components/PostCard.vue";
import BaseButton from "@/components/BaseButton.vue";
import PostsPreviewColumn from "@/components/PostsPreviewColumn.vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";

import { LibraryTypeDTO } from "@/interfaces/LibraryType.dto";
import { BrainLibraryDTO } from "@/interfaces/BrainLibrary.dto";

@Component({
  components: { PostCard, BaseButton, PostsPreviewColumn, BreadCrumbs },
})
export default class Home extends Vue {
  libraryTypes: Array<LibraryTypeDTO> = [];
  investmentStrategy: Array<BrainLibraryDTO> = [];
  alternativeInvestment: Array<BrainLibraryDTO> = [];
  marketUpdate: Array<BrainLibraryDTO> = [];
  esg: Array<BrainLibraryDTO> = [];
  fos: Array<BrainLibraryDTO> = [];

  mounted(): void {
    this.loadInvestmentStrategy();
    this.loadAlternativeInvestment();
    this.loadMarketUpdate();
    this.loadEsg();
    this.loadFos();
  }
  async refresh(): Promise<void> {
    try {
      const { data } = await this.$api.get("/library/types");
      this.libraryTypes = data;
      console.log("lybrary types", this.libraryTypes);
    } catch (error) {
      console.error(error);
    }
  }
  async loadInvestmentStrategy(): Promise<void> {
    try {
      const { data } = await this.$api.get("/library/library-access", {
        params: {
          libraryType: "INVESTMENT_STRATEGY",
          page: 1,
        },
      });
      this.investmentStrategy = data.records;
    } catch (error) {
      console.error(error);
    }
  }
  async loadAlternativeInvestment(): Promise<void> {
    try {
      const { data } = await this.$api.get("/library/library-access", {
        params: {
          libraryType: "ALTERNATIVE_INVESTMENTS",
          page: 1,
        },
      });
      this.alternativeInvestment = data.records;
    } catch (error) {
      console.error(error);
    }
  }

  async loadMarketUpdate(): Promise<void> {
    try {
      const { data } = await this.$api.get("/library/library-access", {
        params: {
          libraryType: "MARKET_UPDATE",
          page: 1,
        },
      });
      this.marketUpdate = data.records;
    } catch (error) {
      console.error(error);
    }
  }

  async loadEsg(): Promise<void> {
    try {
      const { data } = await this.$api.get("/library/library-access", {
        params: {
          libraryType: "ESG",
          page: 1,
        },
      });
      this.esg = data.records;
    } catch (error) {
      console.error(error);
    }
  }

  async loadFos(): Promise<void> {
    try {
      const { data } = await this.$api.get("/library/library-access", {
        params: {
          libraryType: "FOS",
          page: 1,
        },
      });
      this.fos = data.records;
    } catch (error) {
      console.error(error);
    }
  }
}
