
import { Component, Vue, Prop } from "vue-property-decorator";
import AttachmentIcon from "@/components/AttachmentIcon.vue";

import { BrainLibraryDTO } from "@/interfaces/BrainLibrary.dto";

import { formatIOSDateToMMDDYYYY } from "../utils/Functions";

@Component({
  components: { AttachmentIcon },
})
export default class PostCard extends Vue {
  @Prop() item!: BrainLibraryDTO;

  mapLibraryTypePropToDescription = {
    investment_strategy: {
      title: "Investment strategy",
      libraryPageParam: "/home/brain-library/investment_strategy",
      show: false,
    },
    alternative_investments: {
      title: "Alternative investments",
      libraryPageParam: "/home/brain-library/alternative_investments",
      show: true,
    },
    market_update: {
      title: "Markets and managers updates",
      libraryPageParam: "/home/brain-library/market_update",
      show: false,
    },
    private_debt: {
      title: "Private debt",
      libraryPageParam: "/home/brain-library/private_debt",
      show: true,
    },
    private_equity: {
      title: "Private equity",
      libraryPageParam: "/home/brain-library/private_equity",
      show: true,
    },
    real_estate: {
      title: "Real estate",
      libraryPageParam: "/home/brain-library/real_estate",
      show: true,
    },
    specialty_finance: {
      title: "Specialty finance",
      libraryPageParam: "/home/brain-library/specialty_finance",
      show: true,
    },
    venture_capital: {
      title: "Venture capital",
      libraryPageParam: "/home/brain-library/venture_capital",
      show: true,
    },
    esg: {
      title: "Responsible investments (ESG)",
      libraryPageParam: "/home/brain-library/esg",
      show: true,
    },
    fos: {
      title: "Family office solutions (FOS)",
      libraryPageParam: "/home/brain-library/esg",
      show: true,
    },
  };

  get descriptionLibraryType(): any {
    const mapedLibraryType = (this.mapLibraryTypePropToDescription as any)[
      this.item.subtype
        ? this.item.subtype.toLowerCase()
        : this.item.type.toLowerCase()
    ];

    return mapedLibraryType;
  }

  onClickTitle(brainLibrary: BrainLibraryDTO): void {
    this.$router.push({
      name: "PostDetails",
      params: {
        libraryType: brainLibrary.subtype
          ? brainLibrary.subtype.toLowerCase()
          : brainLibrary.type.toLowerCase(),
        postTitle: brainLibrary.title,
        postId: brainLibrary._id,
      },
    });
  }

  formatDate(date: string): string {
    return formatIOSDateToMMDDYYYY(date);
  }

  showNew(item: BrainLibraryDTO): boolean {
    const dateCurrent = new Date();
    const dateItem = new Date(item.scheduleDate);
    const timeDiff = Math.abs(dateItem.getTime() - dateCurrent.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays <= 7;
  }
}
